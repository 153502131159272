.lineabase .notice__container {
  width: 100%;
  background: #5F29B6; /* Color sólido en lugar del degradado */
  height: 155px;
  display: flex;
  position: relative;
}

.lineabase .figure {
  display: none;
}

@media (min-width: 768px) {
  .lineabase .figure {
    display: inline-block;
  }
}

.lineabase .notice__options {
  display: none;
}

@media (min-width: 768px) {
  .lineabase .notice__options {
    position: absolute;
    top: 25px;
    left: 68px;
    display: flex;
    align-items: center;
  }
}

.lineabase .notice__options--text {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
}

.lineabase .notice__title--container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .lineabase .notice__title--container {
    width: 64%;
  }
}

.lineabase .notice__title {
  color: #FAFAFA;
  width: 76%;
  text-align: center;
  font-size: 20px;
}

@media (min-width: 768px) {
  .lineabase .notice__title {
    font-size: 24px;
  }
}

/* Formulario */

.lineabase .form-container {
  background: rgba(154, 208, 255, 0.37);
  padding: 60px 0px;
}

.lineabase .form__requirement {
  font-size: 11px;
  padding: 10px 0;
  text-align: center;
  color: #454854;
  font-weight: 500;
  margin-right: 0;
}

@media (min-width: 768px) {
  .lineabase .form__requirement {
    text-align: center;
  }
}

.lineabase .wirk__form {
  width: 91.5%;
  margin: 0 auto;
  padding-top: 20px;
}

.lineabase .options__information--container {
  background-color: #FDFDFF;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 19px;
  margin-bottom: 20px;
}

.lineabase .options__information--container:nth-child(2) {
  padding-bottom: 40px;
}

.lineabase .options__information--title {
  display: flex;
  justify-content: center;
  color: #253D90;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #C8D0E3;
  margin-top: 7px;
  padding-left: 0;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .lineabase .options__information--title {
    display: block;
    font-size: 12px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
}

.lineabase .options__information--fields {
  display: block;
  gap: 10px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .lineabase .options__information--fields {
    display: flex;
  }
}

.lineabase .options__information--labels {
  flex: 1;
  padding-right: 0;
  border-right: none;
}

@media (min-width: 768px) {
  .lineabase .options__information--labels {
    padding-right: 15px;
    border-right: 1px solid #C8D0E3;
  }
}

.lineabase .options__information--labels:last-child {
  border-right: none;
}

.lineabase .options__information--label {
  margin-bottom: 5px;
}

.lineabase .form-header {
  text-align: center;
  margin-bottom: 40px;
}

.lineabase .form-row {
  padding-left: 20px;
  margin-top: 30px;
}

.lineabase .form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: #666C7C;
}

.lineabase .form-check-label {
  margin-left: 10px;
  font-size: 10px;
  font-weight: 600;
  color: #1C1B1F;
}

.lineabase .form-input,
.lineabase .form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 11px;
  color: #1C1B1F;
}

.lineabase .form-input:focus,
.lineabase .form-select:focus {
  outline: none;
  border-color: #007bff;
}

.lineabase .form-check-input:checked {
  background-color: #253D90;
  border-color: transparent;
}

.lineabase .form-check-input:focus {
  box-shadow: none;
}

.lineabase .data__treatment {
  background-color: #C7E0F5;
  border-radius: 10px;
}

.lineabase .data__treatment--text {
  color: #1C1B1F;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  padding: 24px 28px;
}

.lineabase .form-submit-button {
  background-color: #253D90;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 20px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.lineabase .form-submit-button:hover {
  background-color: #12186B;
}  

.lineabase .empleados-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.lineabase .empleados-column {
  flex: 1;
}

.lineabase .indicadores-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 10px;
}

.lineabase .indicadores-table th,
.lineabase .indicadores-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

/* Estilos para la verificación de documento */
.lineabase .document-check {
  background-color: #f7f9fc;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  margin: 200px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.lineabase .document-check-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.lineabase .error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.lineabase .error-message {
  color: #d9534f;
  font-size: 10px;
}

.lineabase .autodiagnostico-button {
  background-color: #5cb85c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.lineabase .autodiagnostico-button:hover {
  background-color: #4cae4c;
}

/* Estilos para el mensaje de agradecimiento */
.thank_you {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 70vh;
  display: block;
  background-color: #f9fafa;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .thank_you {
    display: flex;
  }
}

@media (max-width: 768px) {
  .thank_you__image {
    display: flex;
    justify-content: center;
  }
}

.thank_you__link {
  flex: auto;
  appearance: none;
  border: 1px solid #D0D0D0;
  border-radius: 10px;
  box-sizing: border-box;
  background: #253D90;
  display: inline-block;
  font-size: 12.5px;
  font-weight: 600;
  line-height: normal;
  margin: 5px;
  min-height: 2.75em;
  outline: none;
  padding: 10px;
  text-align: left;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  color: #FAFAFA;
}

.thank_you__link:hover {
  color: #FAFAFA;
  background: #12186B;
}