.second__section {
  background-color: #fff;
}

.second__section--container {
  width: 85%;
  margin: 0 auto;
}

.second__section--text {

  padding-left: 10px;
  border-left: 3px solid #EF0074;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .second__section--text {
    width: 70%;
    margin-bottom: 30px;
    border-left: 2px solid #EF0074;
    margin: 0;
  }
}

.second__section--text h3 {
  font-weight: 700;
  font-size: 20px;
  color: #12186B;
  margin-bottom: 3px;
}

@media (min-width: 768px) {
  .second__section--text h3 {
    font-size: 25px;
  }
}

.second__section--text p {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 50px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .second__section--text p {
    line-height: 17px;
  }
}

.second__section--categorys {
  margin-top: 30px;
}

.second__sections--items {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.second__sections--items h5 {
  margin-top: 10px;
  color: #12186B;
  font-weight: 700;
  font-size: 16px;
}

.second__sections--items p {
  font-size: 12px;
}

.icon {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: linear-gradient(180deg, #F16022 0%, #EF076D 100%);
}

@media (max-width: 768px) {
  .arrow {
    display: none !important;
  }
}