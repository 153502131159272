.first__section {
  background: linear-gradient(180deg, #f9fafa, #eaf5fe);
  padding: 0;
  padding-top: 32px;
}

.first__section--container {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
}

@media (min-width: 768px) {
  .first__section--container {
    flex-direction: row;
  }
}

.first_section--image-container {
  display: flex;
}

@media (min-width: 768px) {
  .first_section--image-container {
    display: block;
  }
}

.first_section--image {
  width: 80%;
  height: auto;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .first_section--image {
    width: auto;
    height: 530px;
    margin: 0;
  }

}

.first_section--text {
  width: 81%;
  padding-top: 30px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .first_section--text {
    width: 43%;
    padding-top: 112px;
    padding-left: 8px;
    margin: 0;
  }
}

.first-text {
  width: 95%;
  margin: 0 auto;
  padding-left: 12px;
  border-left: 3px solid #EF0074;
}

@media (min-width: 768px) {
  .first-text {
    width: 100%;
    border-left: 2px solid #EF0074;
    margin: 0;
  }

}

.first-text p {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  color: #12186B;
}

@media (min-width: 768px) {
  .first-text p {
    font-size: 20px;
    line-height: 27px;
  }
}

.second-text {
  width: 95%;
  margin: 0 auto;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .second-text {
    padding-left: 12px;
    width: auto;
    margin: 0;
  }

}

.second-text p {
  margin-top: 22px;
  color: #454854;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}