.notice__container {
  width: 100%;
  background: linear-gradient(180deg, #6EB6FC 0%, #5F29B6 100%);
  height: 155px;
  display: flex;
  position: relative;
}

.figure {
  display: none;
}

@media (min-width: 768px) {
  .figure {
    display: inline-block;
  }
}

.notice__options {
  display: none;
}

@media (min-width: 768px) {
  .notice__options {
    position: absolute;
    top: 25px;
    left: 68px;
    display: flex;
    align-items: center;
  }
}

.notice__options--text {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
}

.notice__title--container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .notice__title--container {
    width: 64%;
  }
}

.notice__title {
  color: #FAFAFA;
  width: 76%;
  text-align: center;
  font-size: 20px;
}

@media (min-width: 768px) {
  .notice__title {
    font-size: 24px;
  }
}

/* Formulario */

.form-container {
  background: rgba(154, 208, 255, 0.37);
  padding-bottom: 60px;
}

.form__requirement {
  font-size: 11px;
  padding: 10px 0;
  text-align: center;
  color: #454854;
  font-weight: 500;
  margin-right: 0;
}

@media (min-width: 768px) {
  .form__requirement {
    text-align: center;
  }
}

.wirk__form {
  width: 91.5%;
  margin: 0 auto;
}

.options__information--container {
  background-color: #FDFDFF;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 19px;
  margin-bottom: 20px;
}

.options__information--container:nth-child(2) {
  padding-bottom: 40px;
}

.options__information--title {
  display: flex;
  justify-content: center;
  color: #253D90;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #C8D0E3;
  margin-top: 7px;
  padding-left: 0;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .options__information--title {
    display: block;
    font-size: 12px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
}

.options__information--fields {
  display: block;
  gap: 10px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .options__information--fields {
    display: flex;
  }
}

.options__information--labels {
  flex: 1;
  padding-right: 0;
  border-right: none;
}

@media (min-width: 768px) {
  .options__information--labels {
    padding-right: 15px;
    border-right: 1px solid #C8D0E3;
  }
}

.options__information--labels:last-child {
  border-right: none;
}

.options__information--label {
  margin-bottom: 5px;
}

.form-header {
  text-align: center;
  margin-bottom: 40px;
}

.form-row {
  padding-left: 20px;
  margin-top: 30px;
}


.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.2px;
  color: #666C7C;
}

@media (min-width: 768px) {
  .form-label {
    font-size: 10px;
    font-weight: 500;
  }
}

.form-check-label {
  margin-left: 10px;
  font-size: 10px;
  font-weight: 600;
  color: #1C1B1F;
}

.form-input,
.form-select {
  width: -webkit-fill-available;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  color: #1C1B1F;
}

@media (min-width: 768px) {

  .form-input,
  .form-select {
    font-size: 11px;
  }
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #007bff;
}

.form-check-input:checked {
  background-color: #253D90;
  border-color: transparent;
}

.form-check-input:focus {
  box-shadow: none;
}

.data__treatment {
  background-color: #C7E0F5;
  border-radius: 10px;
}

.data__treatment--text {
  color: #1C1B1F;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  padding: 24px 28px 0px 28px;
}

a.data__treatment--text {
  color: #12186B;
  padding: 0%;
}

.data__treatment--check {
  color: #1C1B1F;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  padding: 10px 28px 24px 28px;
}

.button__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .button__container {
    display: block;
  }
}

.form-submit-button {
  background-color: #253D90;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 20px;
  font-weight: 600;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .form-submit-button {
    border-radius: 4px;
  }
}

.form-submit-button:hover {
  background-color: #12186B;
}

.error-message {
  color: red;
}