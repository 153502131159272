.not_found {
    width: 95%;
    margin: 0 auto;
    padding: 0;
    height: 100vh;
    display: block;
    background-color: #f9fafa;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {
    .not_found {
        display: flex;
    }
}

@media (max-width: 768px) {
    .not_found__image {
        display: flex;
        justify-content: center;
    }
}

.not_found__link {
    flex: auto;
    appearance: none;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    box-sizing: border-box;
    background: #253D90;
    display: inline-block;
    font-size: 12.5px;
    font-weight: 600;
    line-height: normal;
    margin: 5px;
    min-height: 2.75em;
    outline: none;
    padding: 10px;
    text-align: left;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
    color: #FAFAFA;
}

.not_found__link:hover {
    color: #FAFAFA;
    background: #12186B;
}