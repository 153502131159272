.hero {
  background: url(../../img/hero_image.jpg) no-repeat center/cover;
  width: auto;
  height: 325px;
  position: relative;
}

@media (min-width: 768px) {
  .hero {
    min-height: 67vh;
  }
}

.hero__content {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.57);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero__content h1 {
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 25px;
}

@media (min-width: 768px) {
  .hero__content h1 {
    line-height: 46px;
    font-size: 47px;
  }
}

.hero__content p {
  text-decoration: none;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 700;
  width: 63%;
  background-color: #EF0074;
  padding: 8px 22px;
}

@media (min-width: 480px) {
  .hero__content p {
    width: 53%;
  }
}

@media (min-width: 768px) {
  .hero__content p {
    width: auto;
    padding: 3px 15px;
  }
}