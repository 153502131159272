.footer__page {
  width: 85%;
  margin: 0 auto;
  padding-top: 21px;
}

@media (min-width: 768px) {
  .footer__page {
    width: 100%;
    margin: 0;
    padding-top: 21px;
  }
}

.footer__content {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .footer__content {
    width: 89%;
    margin: 0 auto;
  }
}

.footer__content--container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .footer__content--container {
    flex-direction: row;
  }
}

.footer__section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .footer__section {
    flex-direction: row;
    gap: 83px;
  }
}

.footer__logos {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .footer__logos {
    justify-content: start;
  }

}

.footer_logos--wirk {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 22px;
  border-left: 1px solid #E9DBDB;
}

.wirk--span {
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.footer__direction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  gap: 2px;
  text-transform: capitalize;
  border-bottom: 1px solid #E9DBDB;
}

@media (min-width: 768px) {
  .footer__direction {
    border-bottom: none;
  }
}

.footer__direction--span {
  font-weight: 700;
  color: #454854;
}

.footer__direction--text {
  line-height: 1.18;
  font-weight: 500;
}

.footer__direction--text:last-child {
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .footer__direction--text:last-child {
    padding-bottom: 0;
  }
}

@media (min-width: 768px) {
  .footer__direction--text {
    font-weight: normal;
  }
}

.footer__contact {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  font-size: 11.2px;
  border-bottom: 1px solid #E9DBDB;
}

@media (min-width: 768px) {
  .footer__contact {
    border-left: 1px solid #E9DBDB;
    border-right: 1px solid #E9DBDB;
    border-bottom: none;
  }

  .footer__contact:last-child() {
    width: 100%;
  }
}

.footer__contact--text {
  display: flex;

}

.footer__contact--text:last-child {
  width: 99%;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .footer__contact--text {
    padding-left: 20px;
  }

  .footer__contact--text:last-child {
    padding-bottom: 0;
  }
}

.contact--text-container {
  margin-left: 10px;
}

.footer__contact--span {
  font-weight: 700;
  color: #454854;
  font-size: 12px;
  text-transform: capitalize;
}

.footer__contact--link {
  color: #454854;
  font-weight: 500;
}

@media (min-width: 768px) {
  .footer__contact--link {
    font-weight: normal;
  }
}

.footer__tools {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 12px;
}

.footer__tools--span {
  color: #12186B;
  font-weight: 700;
  font-size: 17px;
}

@media (min-width: 768px) {
  .footer__tools--span {
    font-size: 12px;
  }
}

.footer__tools--text {
  font-size: 13px;
  font-weight: 500;
  color: #454854;
}

.footer__tools--text:hover,
.footer__contact--link:hover {
  color: #12186B;
  /* Cambia el color a azul al pasar el mouse */
}

@media (min-width: 768px) {
  .footer__tools--text {
    font-size: 11px;
    font-weight: normal;
  }

}

.footer__socialmedia {
  margin-top: 35px;
  padding-bottom: 16px;
  border-top: 1px solid #E9DBDB;
}

@media (min-width: 768px) {
  .footer__socialmedia {
    border-top: none;
  }
}

.footer__socialmedia--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: -25px;
  gap: 10px;
}

@media (min-width: 768px) {
  .footer__socialmedia--container {
    flex-direction: row;
    justify-content: space-between;
    width: 89%;
    align-items: normal;
    margin: 0 auto;
    gap: 0;
  }
}

.footer__socialmedia--text {
  color: #454854;
  font-size: 12px;
  font-weight: 700;
}